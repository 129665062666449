import * as React from "react";
import { ResponsiveToggleContainerProps, ResponsiveToggleContainerFlexModuleModelResponse } from "./typings";
import { Viewport, ViewSmall, ViewMedium, ViewLarge } from "@shared-ui/viewport-context";
import { EGDSCard, EGDSCardContentSection } from "@egds/react-core/cards";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { getExtraClassNameBasedOnParameters } from "../SimpleContainer/utilityStyles";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { hasRenderableChildren } from "src/stores/ExperienceTemplateStore/shouldRender";
import { RegionChildrenWithGridContainer, RegionChildren } from "src/utils/RegionUtils";

export interface ResponsiveToggleContainerContext {
  modulesHaveBorder?: boolean;
}

const defaultContext = {
  modulesHaveBorder: false,
};

export const ResponsiveToggleContainerContext = React.createContext<ResponsiveToggleContainerContext>(defaultContext);

export const ResponsiveToggleContainer = withStores(
  "flexModuleModelStore",
  "context"
)(
  observer((props: ResponsiveToggleContainerProps) => {
    const { blossomComponent, flexModuleModelStore, templateComponent } = props;

    /* istanbul ignore next */
    if (!templateComponent || !blossomComponent) {
      return null;
    }

    const {
      metadata: { id, name: containerName = "" },
    } = templateComponent;
    const model = (flexModuleModelStore.getModel(id) as ResponsiveToggleContainerFlexModuleModelResponse) || null;

    if (!model || !hasRenderableChildren(templateComponent, flexModuleModelStore)) {
      return null;
    }

    const { mobileWidth, title, innerTitle, tabletWidth, desktopWidth, collapsed, hasBorder } = model;

    const commonClassNames = [getExtraClassNameBasedOnParameters(model, containerName)]
      .filter((classes) => classes !== "")
      .join(" ");

    const ResponsiveContainerContent = (containerProps: { includeTitle: boolean; classNames: string }) => (
      <div className={` ${commonClassNames} ${containerProps.classNames} `} role="region">
        {title && (
          <EGDSSpacing>
            <EGDSHeading tag="h2" size={3} className={` ${!containerProps.includeTitle && "is-visually-hidden"}`}>
              {title}
            </EGDSHeading>
          </EGDSSpacing>
        )}
        {innerTitle && (
          <EGDSSpacing padding={{ inlinestart: "three" }}>
            <EGDSHeading tag="h3" size={3}>
              {innerTitle}
            </EGDSHeading>
          </EGDSSpacing>
        )}
        <RegionChildrenWithGridContainer templateComponent={templateComponent} blossomComponent={blossomComponent} />
      </div>
    );
    const [isVisible, setIsVisible] = React.useState(!collapsed);
    const toggle = React.useCallback(() => setIsVisible(!isVisible), [isVisible]);
    const expandoKeyboardPress = (e: React.KeyboardEvent) => {
      if (e.key === "Enter" || e.key === " ") {
        toggle();
      }
    };

    const flexContent = (
      <>
        {title && (
          <EGDSSpacing>
            <EGDSHeading tag="h2" size={3}>
              {title}
            </EGDSHeading>
          </EGDSSpacing>
        )}
        {innerTitle && (
          <EGDSSpacing padding={{ inlinestart: "three" }}>
            <EGDSHeading tag="h3" size={3}>
              {innerTitle}
            </EGDSHeading>
          </EGDSSpacing>
        )}
        <RegionChildren templateComponent={templateComponent} blossomComponent={blossomComponent} />
      </>
    );

    return (
      <ResponsiveToggleContainerContext.Provider value={{ modulesHaveBorder: hasBorder }}>
        <Viewport>
          {mobileWidth === "hidden" ? (
            <ViewSmall />
          ) : (
            <ViewSmall>
              <EGDSSpacing padding={{ block: "three", inline: "three" }}>
                <div className="ResponsiveToggleContainer SimpleContainer">
                  <EGDSCard>
                    <div
                      className="CallToAction"
                      tabIndex={0}
                      role="button"
                      aria-expanded={isVisible}
                      onClick={toggle}
                      data-testid="expando-toggle"
                      onKeyDown={expandoKeyboardPress}
                    >
                      <EGDSCardContentSection padded>
                        <EGDSLayoutFlex>
                          <EGDSLayoutFlexItem grow={1}>
                            <EGDSText size={400} weight="bold" theme="default">
                              {title}
                            </EGDSText>
                          </EGDSLayoutFlexItem>
                          <EGDSLayoutFlexItem alignSelf="center" grow={0}>
                            <EGDSIcon name={isVisible ? "expand_less" : "expand_more"} size={EGDSIconSize.SMALL} />
                          </EGDSLayoutFlexItem>
                        </EGDSLayoutFlex>
                      </EGDSCardContentSection>
                    </div>
                    <EGDSCardContentSection
                      padded={["blockend", "inlinestart", "inlineend"]}
                      className={`${isVisible ? "" : "is-hidden"}`}
                    >
                      <EGDSSpacing padding={{ blockend: "one", inline: "one" }}>
                        <div>
                          <ResponsiveContainerContent classNames="SimpleContainer" includeTitle={false} />
                        </div>
                      </EGDSSpacing>
                    </EGDSCardContentSection>
                  </EGDSCard>
                </div>
              </EGDSSpacing>
            </ViewSmall>
          )}

          {tabletWidth === "hidden" ? (
            <ViewMedium />
          ) : (
            <ViewMedium>
              <EGDSSpacing padding={{ block: "three", inline: "three" }}>
                <div className={`ResponsiveToggleContainer SimpleContainer ${commonClassNames}`} role="region">
                  {flexContent}
                </div>
              </EGDSSpacing>
            </ViewMedium>
          )}

          {desktopWidth === "hidden" ? (
            <ViewLarge />
          ) : (
            <ViewLarge>
              <EGDSSpacing padding={{ block: "three", inline: "three" }}>
                <div className={`SimpleContainer ResponsiveToggleContainer ${commonClassNames}`} role="region">
                  {flexContent}
                </div>
              </EGDSSpacing>
            </ViewLarge>
          )}
        </Viewport>
      </ResponsiveToggleContainerContext.Provider>
    );
  })
);

export default ResponsiveToggleContainer;
